import React from "react";

export default function portfolio() {
  return (
    <>
      <div className="container fluid">
        <div className="top">
          <div className="spotlight">
            <div className="portfolio">
              <span className="port-text">Portfolio</span>
              <div className="soon-text">Check back soon...</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
