import React, { useEffect, useState } from "react";

import { motion, useTransform, useViewportScroll } from "framer-motion";
import ProgressiveImage from "react-progressive-image";
//Components
import ScrollForMore from "../components/scrollForMore";
import Portfolio from "../components/portfolio";

//Images
import JoelImageLarge from "../images/joel_LC.jpg";
import JoelImageSmall from "../images/joel_SC.jpg";

//Ease
const transition = { duration: 1, ease: [0.6, 0.01, -0.05, 0.9] };

const firstName = {
  animate: {
    transition: {
      delayChildren: 0.6,
      staggerChildren: 0.04,
      staggerDirection: -1,
    },
  },
};

const lastName = {
  animate: {
    transition: {
      delayChildren: 0.6,
      staggerChildren: 0.04,
      staggerDirection: 1,
    },
  },
};

const letter = {
  initial: {
    y: 400,
  },
  animate: {
    y: 0,
    transition: { ...transition },
  },
};

const Spotlight = ({ imageDetails }) => {
  const { scrollYProgress } = useViewportScroll();
  const scale = useTransform(scrollYProgress, [0, 1], [1.05, 0.9]);

  const [scrollable, setScrollable] = useState(false);

  useEffect(() => {
    if (!scrollable) {
      document.querySelector("body").classList.add("no-scroll");
    } else {
      document.querySelector("body").classList.remove("no-scroll");
    }
  }, [scrollable]);

  return (
    <motion.div
      onAnimationComplete={() => setScrollable(true)}
      initial="initial"
      animate="animate"
      exit="exit"
      className="single"
    >
      <div className="container fluid">
        <div className="row center top-row">
          <div className="top">
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.8, ...transition },
              }}
              className="details"
            >
              <div className="location">
                <span>52.520008</span>
                <span>13.404954</span>
              </div>
              <div className="git">GitHub: @jsb1138</div>
            </motion.div>
            <motion.div className="spotlight">
              <motion.span variants={firstName} className="first">
                <motion.span variants={letter}>J</motion.span>
                <motion.span variants={letter}>o</motion.span>
                <motion.span variants={letter}>e</motion.span>
                <motion.span variants={letter}>l</motion.span>
              </motion.span>
              <motion.span variants={lastName} className="last">
                <motion.span variants={letter}>B</motion.span>
                <motion.span variants={letter}>o</motion.span>
                <motion.span variants={letter}>y</motion.span>
                <motion.span variants={letter}>c</motion.span>
                <motion.span variants={letter}>h</motion.span>
                <motion.span variants={letter}>u</motion.span>
                <motion.span variants={letter}>k</motion.span>
              </motion.span>
            </motion.div>
          </div>
        </div>
        <div className="row bottom-row">
          <div className="bottom">
            <div className="image-container-single">
              <motion.div
                className="thumbnail-single"
                initial={{
                  y: "-50%",
                  width: imageDetails.width,
                  height: imageDetails.height,
                  overflow: "hidden",
                }}
                animate={{
                  y: "0",
                  width: "100%",
                  height: "800px",
                  transition: { ...transition },
                }}
              >
                <div className="frame-single">
                  <ProgressiveImage
                    src={JoelImageLarge}
                    placeholder={JoelImageSmall}
                  >
                    {(src) => (
                      <motion.img
                        style={{ scale: scale }}
                        initial={{ scale: 2.26, y: "58%", x: "2.7%" }}
                        animate={{
                          scale: 1.05,
                          y: "0%",
                          x: "0",
                          transition: { ...transition },
                        }}
                        src={src}
                        alt="joel boychuk"
                      />
                    )}
                  </ProgressiveImage>
                </div>
              </motion.div>
            </div>
          </div>
          <ScrollForMore />
        </div>
      </div>
      <div className="detailed-information">
        <div className="container">
          <div className="row">
            <h2 className="title">
              A front end freak in a <br /> full stack world.
              {/* The insiration behind the artwork & <br /> what it means. */}
            </h2>
            <p>
              Your business is your baby and the web is where it shines
              brightest. Give your business its best chance at success and hire
              a full stack developer who has eyes for the front end. Life isn't
              static and your business isn't boring. Your online presence should
              reflect that. Get in touch and get back to what you do best.
              <i> Growing your business</i>. Leave the dynamic, eye-catching,
              interactive web experience to me.
            </p>
          </div>
        </div>
      </div>
      <Portfolio />
    </motion.div>
  );
};

export default Spotlight;
