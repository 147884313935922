import * as React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import ProgressiveImage from "react-progressive-image";

//Images
import JoelImageLarge from "../images/joel_LC.jpg";
import JoelImageSmall from "../images/joel_SC.jpg";

const transition = { duration: 0.8, ease: [0.6, 0.01, -0.05, 0.9] };

const Home = ({ imageDetails, image }) => (
  <>
    <main>
      <div className="container">
        <div className="row center">
          <div className="image-container">
            <div
              className="thumbnail"
              ref={image}
              style={{
                width: imageDetails.width,
                height: imageDetails.height,
              }}
            >
              <Link to={`/spotlight`}>
                <ProgressiveImage
                  src={JoelImageLarge}
                  placeholder={JoelImageSmall}
                >
                  {(src) => (
                    <motion.img
                      style={{
                        height: "740px",
                        x: "14px",
                        y: "47px",
                      }}
                      // initial={{ height: "740px", x: "47px", y: "16px" }}
                      whileHover={{
                        scale: 1.06,
                        x: "14px",
                        y: "53px",
                        transition: {
                          duration: 0.6,
                          ease: [0.6, 0.01, -0.05, 0.9],
                        },
                      }}
                      exit={{ scale: 1.06, x: "14px", y: "54px" }}
                      src={src}
                      alt="Joel Boychuk"
                    />
                  )}
                </ProgressiveImage>
              </Link>
            </div>
            <motion.div
              exit={{ opacity: 0 }}
              transition={transition}
              className="information"
            >
              <div className="title">Full Stack Developer</div>
              <div className="location">
                <span>28.538336</span>
                <span>-81.379234</span>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </main>
  </>
);

export default Home;
